import { useEffect, useRef } from "react";

export function MatrixRainV12(props: React.PropsWithChildren) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  const createCanvas = (width: number, height: number, ctx: CanvasRenderingContext2D) => {
    // Setting up the letters
    const letters = 'PLAINTEXTSUCKSplaintextsucks'.split('');

    // Setting up the columns
    const fontSize = 10;
    const columns = width / fontSize;

    // Setting up the drops
    var drops: number[] = [];
    for (var i = 0; i < columns; i++) {
      drops[i] = 1;
    }

    // Setting up the draw function
    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, .1)';
      ctx.fillRect(0, 0, width, height);
      for (var i = 0; i < drops.length; i++) {
        var text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = '#0f0';
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (drops[i] * fontSize > height && Math.random() > .95) {
          drops[i] = 0;
        }
      }
    };

    return draw;
  }

  useEffect(() => {
    const resizeCanvas = () => {
      if (canvasRef.current) {
        const width = window.innerWidth;
        const height = window.innerHeight;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (ctx == null) {
          return;
        }

        canvas.width = width;
        canvas.height = height;

        const draw = createCanvas(canvas.width, canvas.height, ctx);

        if (intervalId.current) {
          window.clearInterval(intervalId.current);
        }
        // Loop the animation
        intervalId.current = setInterval(draw, 33);
        console.log(window.innerWidth);
        console.log(window.innerHeight);
      }
    }

    window.addEventListener('resize', resizeCanvas);

    resizeCanvas();

    return () => {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div className="flex justify-center matrixContainer">
      <canvas ref={canvasRef} />
      <div className="overlay">
        {props.children}
      </div>
    </div>
  );
}
