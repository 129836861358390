import './App.css';
import redpill from './assets/redpill-noback.png';
import bluepill from './assets/bluepill-noback.png';
import { MatrixRainV12 } from './MatrixRain';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const resizeImg = () => {
      const pillImageElement = Array.from(document.getElementsByClassName('pillimg') as HTMLCollectionOf<HTMLElement>)

      const pillImageHeight = (350 * window.innerHeight) / 750;
      const pillImageWidth = (850 * window.innerWidth) / 1500;

      pillImageElement.forEach(function (obj, idx) {
        obj.style.height = pillImageHeight + 'px';
      });

      const pillsLeftHref = Array.from(document.getElementsByClassName('redpill') as HTMLCollectionOf<HTMLElement>)

      pillsLeftHref.forEach(function (obj, idx) {
        obj.style.marginRight = (50 * window.innerWidth) / 1500 + 'px';
      });

      const pillsRightHref = Array.from(document.getElementsByClassName('bluepill') as HTMLCollectionOf<HTMLElement>)

      pillsRightHref.forEach(function (obj, idx) {
        obj.style.marginLeft = (50 * window.innerWidth) / 1500 + 'px';
      });

      const pillsOuterElement = Array.from(document.getElementsByClassName('pills') as HTMLCollectionOf<HTMLElement>)

      pillsOuterElement.forEach(function (obj, idx) {
        obj.style.marginTop = (window.innerHeight - pillImageHeight) / 2 + 'px';
        obj.style.marginLeft = (window.innerWidth - pillImageWidth) / 2 + 'px';
      });
    }

    window.addEventListener('resize', resizeImg);

    resizeImg();

    return () => {
      window.removeEventListener('resize', resizeImg);
    };
  }, []);

  return (
    <div className="App">

      <MatrixRainV12>
        <div className='pills'>
          <a href="https://www.sunscreen.tech/" className='redpill'>
            <img src={redpill} className='pillimg' />
          </a>
          <a href="https://www.facebook.com/" className='bluepill' >
            <img src={bluepill} className='pillimg' />
          </a>
        </div>
      </MatrixRainV12>
    </div>
  );
}

export default App;
