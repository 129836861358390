// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pills {
  display: block;
  margin: auto;
  width:auto;
  margin-top: 175px;
  margin-left: 200px;
}

.redpill {
  opacity: 0.5;
  display: inline-block;
  margin-right: 50px;
}

.pillimg {
  height: 350px
}

.bluepill {
  opacity: 0.5;
  display: inline-block;
  margin-left: 50px;
}

.pillimg {
  height: 350px
}

.matrixContainer {
  position: relative;
}

.matrixContainer canvas,
.overlay {
  position: absolute;
}

canvas {
  border: 1px solid black;
}

* {
  margin: 0;
  padding: 0
}

body {
  background: #000;
}

canvas {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.pills {\n  display: block;\n  margin: auto;\n  width:auto;\n  margin-top: 175px;\n  margin-left: 200px;\n}\n\n.redpill {\n  opacity: 0.5;\n  display: inline-block;\n  margin-right: 50px;\n}\n\n.pillimg {\n  height: 350px\n}\n\n.bluepill {\n  opacity: 0.5;\n  display: inline-block;\n  margin-left: 50px;\n}\n\n.pillimg {\n  height: 350px\n}\n\n.matrixContainer {\n  position: relative;\n}\n\n.matrixContainer canvas,\n.overlay {\n  position: absolute;\n}\n\ncanvas {\n  border: 1px solid black;\n}\n\n* {\n  margin: 0;\n  padding: 0\n}\n\nbody {\n  background: #000;\n}\n\ncanvas {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
